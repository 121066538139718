/*!
 * Copyright 2015-2016, Ten Eleven Ltd.
 * All rights reserved.
 * 
 * This source code is licensed under the Apache 2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 * 
 * @providesModule Searchkit
 * 
 */
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.rc-slider-handle:hover {
  border-color: #57c5f7; }

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0); } }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }
.sk-action-button, .sk-no-hits__step-action, .sk-range-input__submit {
  border: 1px solid #ccc;
  color: #08c;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  outline: none; }
  .sk-action-button:hover, .sk-no-hits__step-action:hover, .sk-range-input__submit:hover {
    text-decoration: underline; }
  .sk-action-button:active, .sk-no-hits__step-action:active, .sk-range-input__submit:active {
    color: black; }

.sk-spinning-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #000;
  transform: translateZ(0);
  animation: spinning-loader 1.1s infinite linear; }

.sk-spinning-loader,
.sk-spinning-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px; }

@keyframes spinning-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.sk-search-box {
  flex: auto; }
  .sk-search-box form {
    display: flex;
    margin: 0;
    position: relative; }
  .sk-search-box__icon {
    flex: 0 20px 20px;
    margin: 10px 0 0 10px;
    opacity: 0.3; }
    .sk-search-box__icon:before {
      content: "";
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK) no-repeat top left;
      background-size: contain;
      height: 20px;
      width: 20px;
      display: block; }
  .sk-search-box input.sk-search-box__text {
    padding: 10px 20px;
    width: 100%;
    flex: 3;
    font-size: 18px;
    border: none;
    height: initial;
    line-height: initial; }
    .sk-search-box input.sk-search-box__text:focus {
      outline: 0; }
  .sk-search-box__action {
    height: 40px;
    visibility: hidden; }
  .sk-search-box__loader {
    flex: 0 20px 20px;
    align-self: flex-end;
    margin: 10px; }
    .sk-search-box__loader.is-hidden {
      display: none; }

.sk-hits-stats__info {
  font-size: 14px;
  color: #777; }

.sk-action-button, .sk-no-hits__step-action, .sk-range-input__submit {
  border: 1px solid #ccc;
  color: #08c;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  outline: none; }
  .sk-action-button:hover, .sk-no-hits__step-action:hover, .sk-range-input__submit:hover {
    text-decoration: underline; }
  .sk-action-button:active, .sk-no-hits__step-action:active, .sk-range-input__submit:active {
    color: black; }

.sk-no-hits {
  text-align: center;
  margin-top: 60px;
  color: #444; }
  .sk-no-hits__info {
    font-size: 14px; }
  .sk-no-hits__steps {
    margin-top: 20px; }

.sk-filter-group-items {
  display: inline-block; }
  .sk-filter-group-items__title, .sk-filter-group-items__list, .sk-filter-group-items__value {
    display: inline-block; }
  .sk-filter-group-items__title {
    font-weight: bold; }
    .sk-filter-group-items__title:after {
      content: ": "; }
  .sk-filter-group-items__value {
    padding-left: 4px; }
    .sk-filter-group-items__value:hover {
      text-decoration: line-through;
      cursor: pointer;
      color: #fa2a00; }
    .sk-filter-group-items__value:after {
      display: inline-block;
      content: ", "; }
    .sk-filter-group-items__value:last-child:after {
      content: ""; }

.sk-filter-group__remove-action {
  display: inline-block;
  cursor: pointer;
  font-weight: 700; }

.sk-action-bar .sk-filter-groups {
  display: flex;
  flex-flow: row;
  margin: 0 10px 10px 0;
  color: #08c; }

.sk-action-bar .sk-filter-group {
  border: none;
  background: #f4f4f4;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  margin-right: 10px; }
  .sk-action-bar .sk-filter-group-items__title {
    font-weight: 300; }
  .sk-action-bar .sk-filter-group-items__list {
    padding: 10px 0; }
  .sk-action-bar .sk-filter-group__remove-action {
    border-left: 1px solid #ddd;
    padding: 0 15px 0 10px;
    margin-left: 10px;
    font-size: 14px; }
    .sk-action-bar .sk-filter-group__remove-action:hover {
      color: #fa2a00; }

.sk-layout__filters .sk-filter-groups {
  display: block;
  width: 100%;
  margin-right: 10px;
  color: #666; }

.sk-layout__filters .sk-filter-group {
  border: none;
  display: block;
  font-size: 14px;
  line-height: 20px; }
  .sk-layout__filters .sk-filter-group__remove-action {
    padding-right: 10px; }

.sk-item-list.is-disabled {
  display: none; }

.sk-item-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-item-list-option input.sk-item-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-item-list-option.is-active .sk-item-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-item-list-option.is-selected .sk-item-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-item-list-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-item-list-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }
  .sk-item-list-option.is-histogram {
    z-index: 1; }
  .sk-item-list-option.is-histogram .sk-item-list-option__count {
    padding-right: 4px;
    color: #999; }
  .sk-item-list-option__bar {
    position: absolute; }
  .sk-item-list-option__bar {
    display: inline-block;
    position: absolute;
    background: #e7e7e7;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px; }
    .sk-item-list-option__bar-container {
      position: absolute;
      right: 0;
      top: 3px;
      bottom: 3px;
      width: 40%;
      max-width: 60px;
      display: inline-block;
      z-index: -1;
      background: #f4f4f4;
      border-radius: 8px;
      overflow: hidden; }

.sk-select {
  position: relative;
  cursor: pointer;
  max-height: 30px;
  display: inline-block; }
  .sk-select.is-disabled {
    display: none; }
  .sk-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white;
    padding: 6px 10px;
    padding-right: 30px;
    border: 1px solid #ccc;
    outline: 0;
    font-weight: 700;
    font-size: 14px;
    border-radius: 3px;
    color: black;
    height: initial;
    line-height: initial; }
  .sk-select:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 6px solid #666;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none; }

.sk-tabs {
  width: 100%;
  border-bottom: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 4px 0; }
  .sk-tabs.is-disabled {
    display: none; }
  .sk-tabs-option {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: #08c;
    padding: 10px 15px;
    text-decoration: none;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: 3px 3px 0 0; }
    .sk-tabs-option.is-active {
      background-color: white;
      color: #666;
      border-color: #08c;
      cursor: default;
      border: 1px solid #ccc;
      border-bottom-color: transparent; }
    .sk-tabs-option.is-disabled {
      cursor: initial;
      color: #ccc; }
    .sk-tabs-option__text, .sk-tabs-option__count {
      display: inline; }
    .sk-tabs-option__count:before {
      content: " ("; }
    .sk-tabs-option__count:after {
      content: ")"; }

.sk-tag-cloud {
  text-align: justify;
  word-wrap: break-word;
  line-height: 16px;
  font-size: 10px; }
  .sk-tag-cloud.is-disabled {
    display: none; }
  .sk-tag-cloud-option {
    display: inline;
    cursor: pointer;
    font-weight: 300;
    color: #666;
    padding: 4px 2px;
    border-right: none;
    text-decoration: none; }
    .sk-tag-cloud-option__text, .sk-tag-cloud-option__count {
      display: inline; }
    .sk-tag-cloud-option:before {
      content: ' ';
      display: inline; }
    .sk-tag-cloud-option:after {
      content: ' ';
      display: inline; }
    .sk-tag-cloud-option__count:before {
      content: ' ('; }
    .sk-tag-cloud-option__count:after {
      content: ')'; }
    .sk-tag-cloud-option.is-active .sk-tag-cloud-option__text {
      color: #000;
      font-weight: 500; }
    .sk-tag-cloud-option.is-disabled {
      cursor: initial;
      color: #ddd; }

.sk-toggle {
  display: flex;
  align-content: center;
  text-align: center;
  border-radius: 3px;
  max-height: 30px; }
  .sk-toggle.is-disabled {
    display: none; }
  .sk-toggle-option {
    flex: 1;
    background-color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: black;
    padding: 5px 10px;
    border-right: none;
    text-decoration: none;
    white-space: nowrap; }
    .sk-toggle-option:first-child {
      border-radius: 3px 0 0 3px; }
    .sk-toggle-option:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 1px solid #ccc; }
    .sk-toggle-option.is-active {
      background-color: #08c;
      color: white;
      border-color: #08c; }
    .sk-toggle-option.is-disabled {
      cursor: initial;
      color: #ddd; }
    .sk-toggle-option__text, .sk-toggle-option__count {
      display: inline; }
    .sk-toggle-option__count:before {
      content: " ("; }
    .sk-toggle-option__count:after {
      content: ")"; }

.sk-range-input {
  width: 100%;
  align-content: center; }
  .sk-range-input__input {
    display: inline-block;
    width: 68px;
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 30px;
    padding: 0 10px; }
    .sk-range-input__input.is-error {
      border-color: #fa2a00; }
  .sk-range-input__submit {
    display: inline-block;
    background: white;
    height: 30px;
    padding: 0 10px;
    margin-left: 8px; }
  .sk-range-input__to-label {
    display: inline-block;
    line-height: 30px;
    margin: 0 8px;
    color: #444;
    font-size: 14px; }

.sk-range-histogram {
  height: 50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display: flex;
  align-items: flex-end; }
  .sk-range-histogram__bar {
    background: rgba(156, 162, 153, 0.5);
    flex: 1; }
    .sk-range-histogram__bar.is-out-of-bounds {
      background: rgba(156, 162, 153, 0.3); }

.rc-tooltip {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.sk-range-slider {
  padding-bottom: 16px; }
  .sk-range-slider .rc-slider-handle {
    border-color: #ccc; }
  .sk-range-slider .rc-slider-track {
    background-color: rgba(156, 162, 153, 0.5); }
  .sk-range-slider .rc-slider-mark-text-active {
    color: #ccc; }
  .sk-range-slider .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #ccc; }

.sk-hierarchical-menu-list.is-disabled {
  display: none; }

.sk-hierarchical-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  color: black; }

.sk-hierarchical-menu-list__root {
  margin-left: -10px; }

.sk-hierarchical-menu-list__hierarchical-options {
  margin-left: 10px; }

.sk-hierarchical-menu-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-hierarchical-menu-option input.sk-hierarchical-menu-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-hierarchical-menu-option.is-active .sk-hierarchical-menu-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-menu-option.is-selected .sk-hierarchical-menu-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-menu-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-hierarchical-menu-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }

.sk-hierarchical-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px; }

.sk-hierarchical-refinement-list__root {
  margin-left: -10px; }

.sk-hierarchical-refinement-list__hierarchical-options {
  margin-left: 10px; }

.sk-hierarchical-refinement-list.is-disabled {
  display: none; }

.sk-hierarchical-refinement-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-hierarchical-refinement-option input.sk-hierarchical-refinement-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-hierarchical-refinement-option.is-active .sk-hierarchical-refinement-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-refinement-option.is-selected .sk-hierarchical-refinement-option__text {
    font-weight: 700;
    color: #000; }
  .sk-hierarchical-refinement-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-hierarchical-refinement-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }

.sk-input-filter {
  flex: auto;
  border-radius: 3px;
  border: 1px solid #ccc; }
  .sk-input-filter form {
    display: flex;
    margin: 0;
    position: relative; }
  .sk-input-filter__icon {
    flex: 0 20px 20px;
    margin-top: 6px;
    margin-left: 10px;
    opacity: 0.3; }
    .sk-input-filter__icon:before {
      content: "";
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KCgo8IS0tIFRoZSBpY29uIGNhbiBiZSB1c2VkIGZyZWVseSBpbiBib3RoIHBlcnNvbmFsIGFuZCBjb21tZXJjaWFsIHByb2plY3RzIHdpdGggbm8gYXR0cmlidXRpb24gcmVxdWlyZWQsIGJ1dCBhbHdheXMgYXBwcmVjaWF0ZWQuIApZb3UgbWF5IE5PVCBzdWItbGljZW5zZSwgcmVzZWxsLCByZW50LCByZWRpc3RyaWJ1dGUgb3Igb3RoZXJ3aXNlIHRyYW5zZmVyIHRoZSBpY29uIHdpdGhvdXQgZXhwcmVzcyB3cml0dGVuIHBlcm1pc3Npb24gZnJvbSBpY29ubW9uc3RyLmNvbSAtLT4KCgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgoKPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoKCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KCjxwYXRoIGlkPSJtYWduaWZpZXItMi1pY29uIiBkPSJNNDYwLjM1NSw0MjEuNTlMMzUzLjg0NCwzMTUuMDc4YzIwLjA0MS0yNy41NTMsMzEuODg1LTYxLjQzNywzMS44ODUtOTguMDM3CgoJQzM4NS43MjksMTI0LjkzNCwzMTAuNzkzLDUwLDIxOC42ODYsNTBDMTI2LjU4LDUwLDUxLjY0NSwxMjQuOTM0LDUxLjY0NSwyMTcuMDQxYzAsOTIuMTA2LDc0LjkzNiwxNjcuMDQxLDE2Ny4wNDEsMTY3LjA0MQoKCWMzNC45MTIsMCw2Ny4zNTItMTAuNzczLDk0LjE4NC0yOS4xNThMNDE5Ljk0NSw0NjJMNDYwLjM1NSw0MjEuNTl6IE0xMDAuNjMxLDIxNy4wNDFjMC02NS4wOTYsNTIuOTU5LTExOC4wNTYsMTE4LjA1NS0xMTguMDU2CgoJYzY1LjA5OCwwLDExOC4wNTcsNTIuOTU5LDExOC4wNTcsMTE4LjA1NmMwLDY1LjA5Ni01Mi45NTksMTE4LjA1Ni0xMTguMDU3LDExOC4wNTZDMTUzLjU5LDMzNS4wOTcsMTAwLjYzMSwyODIuMTM3LDEwMC42MzEsMjE3LjA0MQoKCXoiLz4KCjwvc3ZnPgoK) no-repeat top left;
      background-size: contain;
      height: 20px;
      width: 20px;
      display: block; }
  .sk-input-filter__text {
    padding: 7px;
    width: 100%;
    flex: 3;
    font-size: 14px;
    background: transparent;
    border: none;
    color: #444;
    height: initial;
    line-height: initial; }
    .sk-input-filter__text:focus {
      outline: 0; }
  .sk-input-filter__action {
    height: 30px;
    visibility: hidden;
    display: none; }
  .sk-input-filter__remove:before {
    flex: 0 20px 20px;
    align-self: flex-end;
    padding: 5px 10px;
    line-height: 30px;
    color: #444;
    content: "x";
    cursor: pointer; }
  .sk-input-filter__remove.is-hidden:before {
    display: none; }

.sk-numeric-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px; }

.sk-numeric-refinement-list.is-disabled {
  display: none; }

.sk-numeric-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-numeric-refinement-list-option input.sk-numeric-refinement-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-numeric-refinement-list-option.is-active .sk-numeric-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-numeric-refinement-list-option.is-selected .sk-numeric-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-numeric-refinement-list-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-numeric-refinement-list-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }

.sk-refinement-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px; }

.sk-refinement-list.is-disabled {
  display: none; }

.sk-refinement-list__view-more-action {
  font-size: 12px;
  color: #08c;
  cursor: pointer;
  margin-top: 5px; }

.sk-refinement-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-refinement-list-option input.sk-refinement-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-refinement-list-option.is-active .sk-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-refinement-list-option.is-selected .sk-refinement-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-refinement-list-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-refinement-list-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }

.sk-selected-filters {
  display: flex;
  flex-flow: row wrap;
  margin-right: 10px; }
  .sk-selected-filters__item {
    flex: 0 0 auto;
    margin: 0 10px 10px 0; }
  .sk-selected-filters-option {
    background: #f4f4f4;
    border: none;
    display: flex;
    padding: 10px 0 10px 10px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 3px; }
    .sk-selected-filters-option__name {
      flex: auto;
      color: #08c; }
    .sk-selected-filters-option__remove-action {
      flex: auto;
      padding: 0 10px;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      color: #08c;
      font-weight: 700;
      border-left: 1px solid #ddd;
      padding: 0 15px 0 10px;
      margin-left: 10px; }
      .sk-selected-filters-option__remove-action:hover {
        color: #fa2a00; }

.sk-range-filter__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px; }

.sk-range-filter.is-disabled {
  display: none; }

.sk-range-filter.is-no-histogram .bar-chart {
  margin-top: 15px; }

.sk-range-filter-value-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px; }
  .sk-range-filter-value-labels__min {
    align-self: flex-start;
    font-size: 12px; }
  .sk-range-filter-value-labels__max {
    align-self: flex-end;
    font-size: 12px; }

.bar-chart {
  height: 50px;
  flex-flow: row nowrap;
  justify-content: space-around;
  display: flex;
  align-items: flex-end; }
  .bar-chart__bar {
    background: rgba(156, 162, 153, 0.5);
    flex: 1; }
    .bar-chart__bar.is-out-of-bounds {
      background: rgba(156, 162, 153, 0.3); }

.sk-reset-filters {
  text-align: center;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  color: #08c; }
  .sk-reset-filters.is-disabled {
    cursor: default;
    color: #ddd; }
  .sk-reset-filters__text {
    font-size: 12px; }

.sk-menu-list__header {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px; }

.sk-menu-list-option {
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  line-height: 22px;
  display: flex;
  font-weight: 300; }
  .sk-menu-list-option input.sk-menu-list-option__checkbox {
    margin: 5px 5px 0 1px;
    flex: 0 0 15px; }
  .sk-menu-list-option.is-active .sk-menu-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-menu-list-option.is-selected .sk-menu-list-option__text {
    font-weight: 700;
    color: #000; }
  .sk-menu-list-option__text {
    flex: auto;
    font-size: 14px;
    color: #666; }
  .sk-menu-list-option__count {
    font-size: 12px;
    text-align: right;
    color: #ccc; }

.sk-tag-filter {
  cursor: pointer; }

.sk-tag-filter-list {
  display: inline; }
  .sk-tag-filter-list .sk-tag-filter {
    display: inline; }
    .sk-tag-filter-list .sk-tag-filter:after {
      content: ", ";
      cursor: initial; }
    .sk-tag-filter-list .sk-tag-filter:last-child:after {
      content: ""; }

.sk-pagination-navigation .sk-toggle-option:first-child,
.sk-pagination-navigation .sk-toggle-option:last-child {
  flex: 4; }

/* TODO : This style is copied from PureCSS for testing purpose, please come back and update !! */
.sk-table {
  /* Remove spacing between table cells (from Normalize.css) */
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
  /* Consider removing this next declaration block, as it causes problems when
  there's a rowspan on the first cell. Case added to the tests. issue#432 */
  /*
  striping:
    even - #fff (white)
    odd  - #f2f2f2 (light gray)
  */
  /* nth-child selector for modern browsers */
  /* BORDERED TABLES */
  /* HORIZONTAL BORDERED TABLES */ }
  .sk-table caption {
    color: #000;
    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center; }
  .sk-table td,
  .sk-table th {
    border-left: 1px solid #cbcbcb;
    /*  inner column border */
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    /*to make ths where the title is really long work*/
    padding: 0.5em 1em;
    /* cell padding */ }
  .sk-table td:first-child,
  .sk-table th:first-child {
    border-left-width: 0; }
  .sk-table thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom; }
  .sk-table td {
    background-color: transparent; }
  .sk-table-odd td {
    background-color: #f2f2f2; }
  .sk-table-striped tr:nth-child(2n-1) td {
    background-color: #f2f2f2; }
  .sk-table-bordered td {
    border-bottom: 1px solid #cbcbcb; }
  .sk-table-bordered tbody > tr:last-child > td {
    border-bottom-width: 0; }
  .sk-table-horizontal td,
  .sk-table-horizontal th {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #cbcbcb; }
  .sk-table-horizontal tbody > tr:last-child > td {
    border-bottom-width: 0; }

.sk-layout__filters .sk-panel {
  margin-bottom: 16px; }

.sk-action-bar .sk-panel__header {
  display: none; }

.sk-panel {
  width: 100%; }
  .sk-panel__header {
    color: black;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px; }
    .sk-panel__header.is-collapsable {
      cursor: pointer;
      user-select: none;
      padding-left: 15px;
      position: relative; }
      .sk-panel__header.is-collapsable:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        left: -1px;
        width: 0;
        height: 0;
        border-top: 5px solid #666;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent; }
      .sk-panel__header.is-collapsable.is-collapsed:after {
        width: 0;
        height: 0;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 5px solid #666;
        margin-top: -5px;
        left: 0; }
  .sk-panel__content.is-collapsed {
    display: none; }
  .sk-panel.is-disabled {
    display: none; }

body {
  margin: 0; }

*, *:before, *:after {
  box-sizing: inherit; }

.sk-layout {
  box-sizing: border-box;
  background: #f4f4f4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }
  .sk-layout__top-bar {
    background: #2a4466;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 0;
    z-index: 100;
    display: flex;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); }
  .sk-layout__body {
    display: flex;
    margin: 60px auto 0;
    min-width: 600px; }
  .sk-layout__filters {
    background: white;
    padding: 15px;
    margin: 15px 0 15px 15px;
    flex: 0 0 246px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08); }
    .sk-layout__filters .sk-refinement-list,
    .sk-layout__filters .sk-range-filter,
    .sk-layout__filters .sk-hierarchical-menu-list,
    .sk-layout__filters .sk-numeric-refinement-list,
    .sk-layout__filters .sk-hierarchical-refinement-list {
      margin-bottom: 20px; }
    .sk-layout__filters .sk-select {
      width: 100%; }
    .sk-layout__filters .sk-range-input {
      margin-top: 10px; }
  .sk-layout__results {
    background: white;
    margin: 15px 15px 15px 15px;
    flex: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    min-height: 600px; }

.sk-top-bar__content {
  display: flex;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%; }

.sk-layout__size-l .sk-top-bar__content {
  width: 1280px; }

.sk-layout__size-l .sk-layout__body {
  max-width: 1280px; }

.sk-layout__size-m .sk-top-bar__content {
  width: 960px; }

.sk-layout__size-m .sk-layout__body {
  max-width: 960px; }

.sk-action-bar {
  display: flex;
  flex-flow: column wrap;
  margin: 0 15px 15px; }
  .sk-action-bar-row {
    display: flex;
    flex-flow: row wrap;
    margin: 0px 0 10px; }
    .sk-action-bar-row:last-child {
      margin: 0; }
  .sk-action-bar .sk-hits-stats {
    line-height: 30px; }

.sk-results-list {
  padding: 15px 0; }

.sk-search-box {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  position: relative;
  display: flex;
  flex: 1; }
  .sk-search-box.is-focused {
    background: white; }
  .sk-search-box.is-focused .sk-search-box__text {
    color: #444; }
  .sk-search-box.is-focused .sk-search-box__text::-webkit-input-placeholder {
    color: #444;
    font-weight: 300; }
  .sk-search-box form {
    width: 100%; }
  .sk-search-box input.sk-search-box__text {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    color: #fff;
    font-size: 16px;
    padding-left: 10px; }
    .sk-search-box input.sk-search-box__text::-webkit-input-placeholder {
      color: white;
      font-weight: 100; }
  .sk-search-box__icon {
    margin: 10px 0 0 15px; }
  .sk-search-box__action {
    display: none; }

.sk-hits-stats {
  align-self: flex-start;
  flex: 1; }

.sk-range-filter .rc-slider-handle {
  border-color: #2a4466; }

.sk-range-filter .rc-slider-track {
  background-color: rgba(156, 162, 153, 0.5); }

.sk-reset-filters {
  border: none;
  display: flex;
  font-size: 14px;
  margin: 0;
  padding-left: 0; }

.sk-pagination-navigation {
  width: 300px;
  margin: 10px auto; }
  .sk-pagination-navigation.is-numbered {
    width: 600px; }

.sk-pagination-select {
  margin: 10px auto;
  text-align: center; }

.sk-hits-grid, .sk-hits-list {
  margin: 0 15px 20px; }

.sk-hits-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start; }
  .sk-hits-grid__no-results {
    text-align: center;
    margin-top: 60px; }
  .sk-hits-grid__item {
    flex: 1 1 250px;
    display: flex;
    margin-bottom: 20px; }

.sk-hits-grid-hit {
  padding: 10px;
  max-width: 250px;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  flex: 190px 0 0; }
  .sk-hits-grid-hit em {
    background: yellow; }
  .sk-hits-grid-hit a {
    text-decoration: none;
    color: #08c; }
  .sk-hits-grid-hit__title {
    margin-top: 10px;
    font-size: 14px; }

.sk-hits-list {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start; }
  .sk-hits-list em {
    background: yellow; }
  .sk-hits-list a {
    text-decoration: none;
    color: #08c; }
  .sk-hits-list__no-results {
    text-align: center;
    margin-top: 60px; }
  .sk-hits-list__item {
    display: flex;
    margin-bottom: 20px; }

.sk-hits-list-hit {
  display: flex; }
  .sk-hits-list-hit__poster {
    width: 200px;
    flex: 0 0 200px;
    padding: 0px 20px; }
    .sk-hits-list-hit__poster img {
      max-width: 100%;
      margin: 0 auto; }
  .sk-hits-list-hit__details {
    flex: 1 0 0;
    padding: 10px; }
  .sk-hits-list-hit__title {
    line-height: 20px;
    margin: 0 0 5px;
    font-size: 18px; }
  .sk-hits-list-hit__subtitle {
    line-height: 20px;
    margin: 0 0 5px;
    font-size: 14px;
    color: #666;
    font-weight: 300; }
  .sk-hits-list-hit__tags {
    line-height: 20px;
    margin: 0;
    font-size: 14px;
    color: #666;
    font-weight: 300;
    list-style: none;
    padding: 0;
    margin-bottom: 10px; }
    .sk-hits-list-hit__tags .sk-tag-filter:hover {
      color: #08c; }
    .sk-hits-list-hit__tags .sk-tag-filter:active {
      color: black; }
  .sk-hits-list-hit__text {
    line-height: 22px;
    max-width: 800px; }

.sk-action-bar .sk-select, .sk-action-bar .sk-toggle {
  margin-left: 15px; }

.sk-layout__filters .sk-select select {
  width: 100%; }
